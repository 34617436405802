import { TableNames } from "@customEnums/TableNames"

enum NistEnvironmentKeys {
    firstName = "firstName",
    middleName = "middleName",
    lastName = "lastName",
    suffix = "suffix",
    fullName = "fullName",
    fullAddress = "fullAddress",
    mailingAddress = "mailingAddress",
    address = "address",
    city = "city",
    state = "state",
    zipCode = "zipCode",
    agencyIdentifier = "agencyIdentifier",
    tcn = "tcn",
    ssn = "ssn",
    birthDate = "birthDate",
    placeOfBirth = "placeOfBirth",
    race = "race",
    gender = "gender",
    height = "height",
    weight = "weight",
    hairColor = "hairColor",
    eyeColor = "eyeColor",
    ori = "ori",
    secondaryOri = "secondaryOri",
    agency = "agency",
    emailAddress = "emailAddress",
    occupation = "occupation",
    transactionType = "transactionType",
    reasonFingerprinted = "reasonFingerprinted"
  }

export enum PageNames {
    TRANSACTION_SUMMARY = "transaction-summary",
    RAPBACK_SUMMARY = "rapback-summary",
    AGENCY_MANAGEMENT = "agency-management",
    BATCH_PRINTING = "batch-printing",
    USER_MANAGEMENT = "user-management",
    APPLICANT_DETAILS = "applicant-details",
    RAPBACK_MAINTENANCE = "rapback-maintenance",
    RAPBACK_PRENOTIFICATIONS = "rapback-prenotifications",
    RAPBACK_VALIDATIONS = "rapback-validations"
}

export interface UserRole {
    value: Role
    label: RoleLabel
    isAdmin: string
    devOnly: boolean
}

export type UserRoles = UserRole[]
export type RoleLabel = Role.mc_admin | Role.mc_edit | Role.mc_readonly | Role.mc_readonly_restricted_view | Role.mc_agency_admin | Role.mc_state_police_user | Role.mc_state_police_admin

export enum Role {
    mc_admin = "Admin Super User",
    mc_edit = "Edit",
    mc_readonly = "Read-Only",
    mc_readonly_restricted_view = "Read-Only Restricted",
    mc_agency_admin = "Agency Admin",
    mc_state_police_user = "State Police User",
    mc_state_police_admin = "State Police Admin"
}

export enum Group {
    "Admin Super User" = "mc_admin",
    "Agency User" = "mc_agency_user",
    "Edit" = "mc_edit",
    "Read-Only" = "mc_readonly",
    "Read-Only Restricted" = "mc_readonly_restricted_view",
    "Agency Admin" = "mc_agency_admin",
    "State Police User" = "mc_state_police_user",
    "State Police Admin" = "mc_state_police_admin"
}

enum DeploymentStates {
    me = "me",
    nm = "nm",
    ga = "ga"
}

export type NistParsing = {
    [key in NistEnvironmentKeys]: { key: string, value?: string }
}

export type LabelValue = { label: string, value: string }

export type TableHeaderCell = { dataIndex: string, label: string, cellType: string, searchable?: boolean }
export type TableHeaderCells = Array<TableHeaderCell>

export type HeaderCell = {
    renderCustomSortIcon?: boolean,
    menuItems?: LabelValue[] | string[]
    tableName?: string,

}

export type HeaderCells = Array<HeaderCell>

interface FilterValues {
    [key: string]: {
        selected: boolean,
        label: string,
        boolMap?: boolean
    }
}

interface Filter {
    filterLabel: string
    filterColumn: string
    filterIsBool: boolean
    filterValues: FilterValues
}

type FilterList = Filter[]

interface TableTools {
    filter: boolean
    manageColumns: boolean
}

interface ColumnVisibility {
    [key: string]: boolean
}

export interface Table {
    tableName: string,
    useQueryPagination: boolean,
    advancedSearch: boolean,
    tableTools: TableTools,
    tableHeaderCells: TableHeaderCells,
    filterList: FilterList,
    columnVisibility: ColumnVisibility
}

export type Tables = Table[]

export type Pages = {
    [key in PageNames]: Tables
}

type Cognito = {
    userRoles: UserRoles
}

enum date {
    startDate = "startDate",
    endDate = "endDate"
}

type Schedule = {
    [key in date]: string
}

export interface SearchOptions {
    limitLowerSearchDate: boolean
    lowerSearchDateLimit?: number
}
export interface TransactionViewing {
    hardLimit: number
    softLimit: number
    extendDays: number
    maxExtensions?: number
    searchOptions: SearchOptions
}

export interface Config {
    cognito: Cognito
    nistParsing: NistParsing
    pages: Pages
    transactionViewing: TransactionViewing
    scheduledMaintenance?: Schedule
}

export type TableConfig = {
    [key in TableNames]?: {
        tableName: string
        tableHeaderCells: TableHeaderCells
        columnVisibility: ColumnVisibility
        transactionViewing: TransactionViewing
        filterList: FilterList
        advancedSearch: boolean
        filter: boolean
        manageColumns: boolean
        useQueryPagination: boolean
        savedSearches?: any,
        dispatchSearch?: Function
    }

}
